<template>
	<!-- a lightbox should always be visible, no matter what.	it should be > insidePage, tab, modal, header ... -->
	<div
		v-if="visible && currentItem"
		class="block fixed w-screen h-screen left-0 top-0 z-[190]"
		@touchstart="touchStart"
		@touchmove="touchMove"
	>
		<div class="background absolute inset-0" />

		<!-- Slidable Content -->
		<transition :name="animationName" mode="out-in">
			<div v-if="currentItem.url" :key="currentItem.url" class="w-full h-full relative">
				<div
					class="absolute bottom-0 right-0 left-0 w-3/4 m-auto flex flex-col items-center justify-center"
					style="top: -2.5rem; height: 80%"
				>
					<div
						v-if="
							currentItem.type === 'youtube' &&
							(!userHasAcceptedExternalCookies || !localStorageAvailable)
						"
						class="w-full h-full bg-color-main-lighter"
					>
						<p v-if="!localStorageAvailable">
							Dein Browser unterstützt die Verwendung von Cookies nicht, die Wiedergabe dieses
							Videos ist daher leider nicht möglich.
						</p>
						<div
							v-else
							class="h-full flex flex-col items-center justify-center cursor-pointer"
							@click="openCookieBanner"
						>
							<HokIcon class="mb-4" :size="12" name="icon:youtube" />
							<p class="px-4 max-w-xs text-center">
								Bitte stimme unseren Cookie-Richtlinien zu, um dieses Video anzuzeigen.
							</p>
							<p class="text-color-main">Zustimmung geben</p>
						</div>
					</div>
					<iframe
						v-else-if="currentItem.type === 'youtube' || currentItem.type === 'link'"
						allow="fullscreen"
						:src="currentItem.url"
						:width="$isMobile.phone ? '100%' : '80%'"
						:height="$isMobile.phone ? 'auto' : '80%'"
						class="shadow-lg border-none"
					/>
					<NuxtImg
						v-else
						loading="lazy"
						:src="currentItem.url"
						:alt="currentItem.title || ''"
						class="max-w-full shadow-lg"
						style="max-height: 80%"
					/>
					<p
						v-if="showCaption && currentItem.title"
						class="p-3 text-color-white text-lg text-center"
					>
						{{ currentItem.title }}
					</p>
				</div>
			</div>
		</transition>

		<!-- Previous Item -->
		<div v-if="media.length > 1" class="absolute inset-y-0 left-0 flex items-center sm:pl-8">
			<HokIcon
				name="icon:arrow-left"
				color="white"
				pointer
				:size="$isMobile.phone ? 6 : 10"
				tabindex="1"
				@click.stop="moveToPreviousItem"
			/>
		</div>

		<!-- Next Item -->
		<div v-if="media.length > 1" class="absolute inset-y-0 right-0 flex items-center sm:pr-8">
			<HokIcon
				name="icon:arrow-right"
				color="white"
				pointer
				:size="$isMobile.phone ? 6 : 10"
				tabindex="2"
				@click.stop="moveToNextItem"
			/>
		</div>

		<!-- Close Lightbox -->
		<div class="absolute inset-x-0 top-0 flex justify-end p-3 sm:pt-5 sm:pr-10">
			<HokIcon
				name="icon:close-slim"
				color="white"
				pointer
				:size="8"
				tabindex="3"
				@click.stop="closeLightbox"
			/>
		</div>

		<!-- Gallery -->
		<div class="absolute inset-x-0 bottom-0 flex justify-center pb-6">
			<div class="flex space-x-2" :class="maskToShow">
				<div
					v-for="item in visiblePreviewItems"
					:key="item.url"
					class="relative cursor-pointer w-12 sm:w-16 h-12 sm:h-16 border-2"
					:class="item.url === currentItem.url ? 'border-color-white' : 'border-transparent'"
					@click="setCurrentPosition(item.url)"
				>
					<HokBgImg
						:source="item.thumbnail"
						:alt="item.title || ''"
						class="absolute inset-0 bg-no-repeat bg-center flex items-center justify-center"
					>
						<HokIcon
							v-if="item.type === 'youtube'"
							color="white"
							name="icon:play"
							class="bg-color-transparent-black rounded-full"
						/>
					</HokBgImg>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
/* eslint-disable vue/no-side-effects-in-computed-properties */
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import type { IMediaContent } from '../types/lightBox';
import { lsTest } from '../helpers/localstorage';
import { EventBus } from '../eventbus';

export default defineComponent({
	name: 'Lightbox',
	emits: ['requestNextSilentBoxItem', 'requestPreviousSilentBoxItem', 'opened', 'closed'],
	data() {
		const maskToShow = '' as 'mask-left' | 'mask-right' | 'mask-both' | '';
		const animationName = 'swipe-left' as 'swipe-right' | 'swipe-left';

		return {
			localStorageAvailable: lsTest(),
			userHasAcceptedExternalCookies: false,
			animationName,
			currentPosition: 0,
			visible: false,
			previousBodyOverflowSetting: '',
			touchHandling: {
				posX: 0,
				posY: 0
			},
			maskToShow,
			EventBus
		};
	},
	computed: {
		currentItem(): IMediaContent {
			return this.media[this.currentPosition];
		},
		visiblePreviewItems(): IMediaContent[] {
			// there are only 5 preview items or less -> return unchanged array & dont apply mask
			if (this.media.length <= 5) {
				this.maskToShow = '';
				return this.media;
			}
			// there are more than 5 preview items and the currentposition hasnt crossed the third item -> return first 5 & apply mask to outer right item
			if (this.currentPosition <= 1) {
				this.maskToShow = 'mask-right';
				return this.media.slice(0, 5);
			}
			// currentposition in given interval  -> return previous 2 and next 2 items & apply mask to both outer items
			if (this.currentPosition >= 2 && this.media.length - this.currentPosition >= 3) {
				if (this.currentPosition >= 3 && this.media.length - this.currentPosition >= 4) {
					this.maskToShow = 'mask-both';
				} else if (this.currentPosition >= 3) {
					this.maskToShow = 'mask-left';
				} else if (this.media.length - this.currentPosition >= 4) {
					this.maskToShow = 'mask-right';
				}
				return this.media.slice(this.currentPosition - 2, this.currentPosition + 3);
			}
			// currentposition approaching last 3 items -> return last 5 & apply mask to outer left item
			this.maskToShow = 'mask-left';
			return this.media.slice(this.media.length - 5);
		}
	},
	mounted() {
		window.addEventListener('keyup', event => this.keyUpChecker(event));

		const acceptedCookie = this.localStorageAvailable && localStorage.getItem('accept-cookies');
		if (acceptedCookie && acceptedCookie.startsWith('[')) {
			const parsedCookie = JSON.parse(acceptedCookie);
			this.userHasAcceptedExternalCookies = !!parsedCookie.includes('externalYoutube');
		}

		this.EventBus.$on('accepted-tracking', parsedCookie => {
			this.userHasAcceptedExternalCookies = !!parsedCookie.includes('externalYoutube');
		});
	},
	beforeUnmount() {
		// make sure scrolling is enabled again if user manages to navigate inside lightbox
		this.removeScrollLock();
		window.removeEventListener('keyup', event => this.keyUpChecker(event));
		this.EventBus.$off('accepted-tracking');
	},
	methods: {
		keyUpChecker(event) {
			switch (event.key) {
				case 'Left': // IE/Edge specific value
				case 'ArrowLeft':
					this.moveToPreviousItem();
					break;
				case 'Right': // IE/Edge specific value
				case 'ArrowRight':
					this.moveToNextItem();
					break;
				case 'Escape':
					this.closeLightbox();
					break;
				default:
				// do nothing
			}
		},
		setCurrentPosition(url) {
			this.currentPosition = this.media.findIndex(item => item.url === url);
		},
		moveToNextItem() {
			this.animationName = 'swipe-left';
			if (this.currentPosition === this.media.length - 1) {
				this.currentPosition = 0;
			} else {
				this.currentPosition += 1;
			}
			this.$emit('requestNextSilentBoxItem');
		},
		moveToPreviousItem() {
			this.animationName = 'swipe-right';
			if (this.currentPosition === 0) {
				this.currentPosition = this.media.length - 1;
			} else {
				this.currentPosition -= 1;
			}
			this.$emit('requestPreviousSilentBoxItem');
		},
		touchStart(event: TouchEvent) {
			const { clientX: x, clientY: y } = event.touches[0];
			this.touchHandling.posX = x;
			this.touchHandling.posY = y;
		},
		touchMove($event: TouchEvent) {
			const { clientX: x, clientY: y } = $event.touches[0];
			const { posX, posY } = this.touchHandling;
			// prevent any y-axis scrolling
			if (posY) {
				$event.preventDefault();
			}
			if (posX === 0 || posY === 0) {
				return;
			}
			const xDiff = posX - x;
			const yDiff = posY - y;
			if (Math.abs(xDiff) > Math.abs(yDiff)) {
				if (xDiff > 0) {
					// left
					this.moveToNextItem();
				} else {
					// right
					this.moveToPreviousItem();
				}
			}
			this.touchHandling.posX = 0;
			this.touchHandling.posY = 0;
		},
		enableScrollLock() {
			// save previous overflow style and set it to hidden to prevent any scrolling interaction
			this.previousBodyOverflowSetting = document.body.style.overflow;
			document.body.style.overflow = 'hidden';
		},
		removeScrollLock() {
			// restore original state and reset settings
			if (document.body.style.overflow) {
				document.body.style.overflow = this.previousBodyOverflowSetting;
				this.previousBodyOverflowSetting = '';
			}
		},
		openLightbox(item: IMediaContent) {
			if (item?.url) {
				this.setCurrentPosition(item.url);
			}

			this.enableScrollLock();
			this.visible = true;
			this.$emit('opened');
		},
		closeLightbox() {
			if (this.visible) {
				this.removeScrollLock();
				this.visible = false;
				this.$emit('closed', this.currentPosition);
			}
		},
		openCookieBanner() {
			this.$cookieBanner.toggle(true, { stage: 'config' });
		}
	},
	props: {
		media: { type: Array as PropType<IMediaContent[]>, required: true },
		showCaption: { type: Boolean, default: true }
	}
});
</script>

<style lang="scss" scoped>
.mask-right {
	mask-image: linear-gradient(95deg, rgba(0, 0, 0, 1) 88%, rgba(0, 0, 0, 0) 100%);
}

.mask-left {
	mask-image: linear-gradient(-95deg, rgba(0, 0, 0, 1) 88%, rgba(0, 0, 0, 0) 100%);
}

.mask-both {
	mask-image: linear-gradient(
		95deg,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 1) 12%,
		rgba(0, 0, 0, 1) 88%,
		rgba(0, 0, 0, 0) 100%
	);
}

.background {
	background: rgba(#000, 0.75);
	backdrop-filter: blur(20px);
}

// Transitions
.swipe-left-enter-active {
	transition: all 0.3s ease;
	opacity: 0;
	transform: translateX(25vw);
}
.swipe-left-leave-active {
	transition: all 0.3s ease;
}
.swipe-left-enter-to {
	opacity: 1;
	transition: all 0.3s ease;
	transform: translateX(0);
}
.swipe-left-leave-to {
	opacity: 0;
	transition: all 0.3s ease;
	transform: translateX(-25vw);
}
.swipe-right-enter-active {
	transition: all 0.3s ease;
	opacity: 0;
	transform: translateX(-25vw);
}
.swipe-right-leave-active {
	transition: all 0.3s ease;
}
.swipe-right-enter-to {
	opacity: 1;
	transition: all 0.3s ease;
	transform: translateX(0);
}
.swipe-right-leave-to {
	opacity: 0;
	transition: all 0.3s ease;
	transform: translateX(25vw);
}
</style>
