<template>
	<div class="container max-w-7xl mb-4 flex" :class="$isMobile.any ? 'flex-col' : 'mt-4'">
		<div v-if="!$isMobile.any" class="relative w-1/5 pr-8">
			<aside class="flex flex-col sticky items-start top-[68px]">
				<div class="border border-color-grey-lightest w-full rounded-lg mb-5">
					<div class="w-full relative bg-color-grey-lightest pt-[100%]">
						<HokBgImg
							v-if="company.logos"
							:source="company.logos"
							:size="$isMobile.any ? 'medium' : 'xlarge'"
							:bg-cover="false"
							alt="Firmenlogo"
							class="rounded-lg absolute inset-0 bg-no-repeat bg-color-white bg-center"
						/>
						<HokBgImg
							v-else
							source="/shared-components-nuxt3/images/logo-placeholder.png"
							:size="$isMobile.any ? 'medium' : 'xlarge'"
							alt="LogoPlaceholder"
							:bg-cover="false"
							class="rounded-lg absolute inset-0 bg-no-repeat bg-center m-6"
						>
							<div
								v-if="company && company.name"
								class="uppercase absolute inset-0 flex items-center justify-center text-[120px]"
							>
								{{ company.name.charAt(0) }}
							</div>
						</HokBgImg>
					</div>
				</div>
				<div v-if="editMode" class="text-center w-full">
					<HokButton
						class="mr-2 right-0 -mt-9 p-1 absolute text-sm"
						style="padding: 0.25rem"
						size="small"
						data-cy="editLogo"
						color="business"
						@click="$emit('edit', 'logo')"
					>
						<HokIcon name="icon:edit" :size="4" color="white"
					/></HokButton>
				</div>
				<ul
					v-if="!editMode && (company.description || hasTrustedUsers || jobCount || locationCount)"
					id="nav-menu"
					class="text-base space-y-1 mb-5 font-light"
				>
					<h3 class="text-2xs text-color-text font-light mb-1">NAVIGATION</h3>
					<li
						v-if="company.description"
						id="nav-about-us"
						@click.capture="e => toAnchor(e, 'about-us')"
					>
						<HokLink to="#about-us" class="hover:underline text-color-text" link-type="internal"
							>Über Uns</HokLink
						>
					</li>
					<li
						v-if="hasTrustedUsers"
						id="nav-contact-person"
						@click.capture="e => toAnchor(e, 'contact-person')"
					>
						<HokLink
							to="#contact-person"
							class="hover:underline text-color-text"
							link-type="internal"
							>Ansprechpartner</HokLink
						>
					</li>
					<li v-if="jobCount" id="nav-jobs" @click.capture="e => toAnchor(e, 'jobs')">
						<HokLink to="#jobs" class="hover:underline text-color-text" link-type="internal"
							>Aktuelle Jobs</HokLink
						>
					</li>
					<li
						v-if="locationCount"
						id="nav-locations"
						@click.capture="e => toAnchor(e, 'locations')"
					>
						<HokLink to="#locations" class="hover:underline text-color-text" link-type="internal"
							>Standorte</HokLink
						>
					</li>
				</ul>
				<HokButton
					v-if="
						company.allowUnsolicitedApplications &&
						!isCompanySwipeActionDiscover &&
						!editMode &&
						displayCTAButtons
					"
					class="mb-3"
					fullwidth="always"
					size="small"
					font-weight="bold"
					link-type="external"
					color="main"
					invisible
					:to="`/apply-company/${company._id}`"
				>
					Initiativ bewerben
				</HokButton>
				<HokButton
					v-if="displayCTAButtons"
					fullwidth="always"
					size="small"
					font-weight="bold"
					:outline="company.allowUnsolicitedApplications && !isCompanySwipeActionDiscover"
					color="main"
					@click="$emit('activate-jobalarm')"
				>
					Jobalarm aktivieren
				</HokButton>
			</aside>
		</div>
		<div :class="$isMobile.any ? 'w-full' : 'w-4/5'">
			<section id="start" class="flex">
				<div
					:class="[
						$isMobile.any ? 'w-full' : 'w-2/3',
						{
							'border-t border-b border-color-white': !$isMobile.any && media && media.length > 2
						}
					]"
				>
					<!-- 65% (~1.54 ratio) is now the ONLY aspect-ratio for company and job image -->
					<div class="relative pb-[65%]">
						<HokBgImg
							:source="coverImages"
							alt="Firmenbild"
							class="bg-no-repeat absolute inset-0 bg-center"
							:class="$isMobile.any ? '-mx-4' : 'rounded-md'"
						/>
						<div
							v-if="$isMobile.any"
							class="absolute inset-x-0 bottom-0 flex justify-center h-16 -mb-12"
						>
							<div v-if="company.logos" class="w-16 h-16 relative rounded-lg bg-color-white">
								<HokBgImg
									:source="company.logos"
									size="medium"
									ssr
									alt="Firmenlogo"
									class="absolute inset-px bg-no-repeat bg-center bg-contain rounded-lg"
									:bg-cover="false"
								/>
							</div>
							<div v-else class="w-16 h-16 relative bg-color-white border border-color-grey-light">
								<HokBgImg
									source="/shared-components-nuxt3/images/logo-placeholder.png"
									class="rounded-lg absolute inset-0 bg-no-repeat bg-color-white bg-center m-1"
								>
									<div
										v-if="company && company.name"
										class="uppercase absolute inset-0 flex items-center justify-center text-[50px]"
									>
										{{ company.name.charAt(0) }}
									</div>
								</HokBgImg>
							</div>
						</div>
						<div
							v-if="!editMode && $isMobile.any && displayCTAButtons"
							class="absolute right-0 top-0 bg-color-transparent-black rounded-md p-2"
							@click="$emit('activate-jobalarm')"
						>
							<HokIcon name="icon:jobalarm" color="white" />
						</div>

						<div v-if="editMode" class="absolute right-4 bottom-4 mr-8 mb-8">
							<HokButton
								class="p-1 absolute text-sm"
								style="padding: 0.25rem"
								size="small"
								data-cy="editCoverImage"
								color="business"
								@click="$emit('edit', 'coverimage')"
							>
								<HokIcon name="icon:edit" :size="4" color="white"
							/></HokButton>
						</div>
					</div>
				</div>
				<div
					v-if="!$isMobile.any"
					class="pl-1 w-1/3 flex-none flex flex-col items-center justify-center min-h-full bg-color-main-light ml-4 rounded-lg"
				>
					<!-- VIEW WITH 3 INFO TILES, PRIORITY: FIELDS > LOCATIONS > JOBCOUNT > INITIATIVE > JOBALARM -->
					<template v-if="enoughDataForSplitTiles">
						<div class="mb-6">
							<NuxtImg src="/shared-components-nuxt3/images/info-tile.png" class="w-28 h-28" />
						</div>
						<div>
							<div
								v-if="jobFieldCount || editMode"
								class="flex flex-col items-center text-center text-base xl:text-lg cursor-pointer w-full mb-4"
								@click="e => toAnchor(e, 'fields')"
							>
								<p class="mb-4">
									<strong>{{ company && company.fields && company.fields.length }}</strong>
									Tätigkeitsbereiche
								</p>
								<div class="border-b border-color-text w-6"></div>
							</div>
							<div
								v-else-if="locationCount"
								class="flex flex-col items-center text-center text-base xl:text-lg cursor-pointer w-full mb-4"
								@click="e => toAnchor(e, 'locations')"
							>
								<p class="mb-4">
									<strong>{{ locationCount }}</strong>
									{{ locationCount > 1 ? 'Standorte' : 'Standort' }}
								</p>
								<div class="border-b border-color-text w-6"></div>
							</div>
							<div
								v-else
								class="flex flex-col items-center text-center text-base xl:text-lg cursor-pointer w-full mb-4"
								@click="e => toAnchor(e, 'jobs')"
							>
								<p class="mb-4">
									<strong>{{ jobCount }}</strong>
									{{ (jobCount && jobCount > 1) || jobCount === 0 ? 'offene Jobs' : 'offener Job' }}
								</p>
								<div class="border-b border-color-text w-6"></div>
							</div>
						</div>
						<div>
							<div
								v-if="locationCount"
								class="flex flex-col items-center text-center text-base xl:text-lg cursor-pointer w-full mb-4"
								@click="e => toAnchor(e, 'locations')"
							>
								<p class="mb-4">
									<strong>{{ locationCount }}</strong>
									{{ locationCount > 1 ? 'Standorte' : 'Standort' }}
								</p>
								<div class="border-b border-color-text w-6"></div>
							</div>
							<div
								v-else-if="jobCount"
								class="flex flex-col items-center text-center text-base xl:text-lg cursor-pointer w-full mb-4"
								@click="e => toAnchor(e, 'jobs')"
							>
								<p class="mb-4">
									<strong> {{ jobCount }} </strong>
									{{ jobCount > 1 || jobCount === 0 ? 'offene Jobs' : 'offener Job' }}
								</p>

								<div class="border-b border-color-text w-6"></div>
							</div>
							<div
								v-else
								class="flex items-center text-center text-base xl:text-lg cursor-pointer w-full mb-4"
								@click="e => toAnchor(e, 'initiative')"
							>
								<HokIcon name="icon:bewerbung" :size="5" color="text" class="mr-2" />
								Initiativbewerbung
							</div>
						</div>
						<div class="flex flex-none">
							<div
								v-if="jobFieldCount && locationCount && jobCount"
								class="text-center text-base xl:text-lg cursor-pointer w-full mb-4"
								@click="e => toAnchor(e, 'jobs')"
							>
								<strong>{{ jobCount }}</strong>

								{{ jobCount > 1 || jobCount === 0 ? 'offene Jobs' : 'offener Job' }}
							</div>
							<div
								v-else-if="company.allowUnsolicitedApplications"
								class="text-center text-base xl:text-lg cursor-pointer flex items-center justify-center w-full mb-4"
								@click="e => toAnchor(e, 'initiative')"
							>
								<HokIcon name="icon:bewerbung" :size="5" color="text" class="mr-2" />
								Initiativbewerbung
							</div>
							<div
								v-else-if="!editMode && displayCTAButtons"
								class="text-center text-base xl:text-lg cursor-pointer flex items-center justify-center w-full mb-4"
								@click="e => toAnchor(e, 'initiative')"
							>
								<HokIcon name="icon:jobalarm" color="text" class="mr-2" />
								Jobalarm
							</div>
						</div>
					</template>

					<!-- VIEW WITH 1 INFO TILE, PRIORITY: FIELDS > LOCATIONS > JOBCOUNT > INITIATIVE > JOBALARM -->
					<template v-else>
						<div class="mb-6">
							<NuxtImg
								src="/shared-components-nuxt3/images/info-tile.png"
								class="w-36 h-36 rounded-full mt-8"
							/>
						</div>
						<div
							v-if="jobFieldCount || editMode"
							class="text-center text-xl leading-none cursor-pointer w-full"
							@click="e => toAnchor(e, 'fields')"
						>
							<strong>{{ company && company.fields && company.fields.length }}</strong>

							Tätigkeitsbereiche
						</div>
						<div
							v-else-if="locationCount"
							class="text-center text-xl leading-none cursor-pointer w-full"
							@click="e => toAnchor(e, 'locations')"
						>
							<strong>{{ locationCount }}</strong>
							{{ locationCount > 1 ? 'Standorte' : 'Standort' }}
						</div>
						<div
							v-else-if="jobCount"
							class="text-center text-xl leading-none cursor-pointer w-full"
							@click="e => toAnchor(e, 'jobs')"
						>
							<strong>{{ jobCount }}</strong>
							{{ jobCount > 1 || jobCount === 0 ? 'offene Jobs' : 'offener Job' }}
						</div>
						<div
							v-else-if="company.allowUnsolicitedApplications"
							class="text-xl cursor-pointer flex items-center justify-center text-center w-full"
							@click="e => toAnchor(e, 'initiative')"
						>
							<HokIcon name="icon:bewerbung" :size="8" color="text" class="mr-2" />
							Initiativbewerbung
							<br />
							erwünscht
						</div>
						<div
							v-else
							class="text-lg cursor-pointer flex items-center justify-center text-center w-full"
							@click="e => toAnchor(e, 'initiative')"
						>
							<HokIcon name="icon:jobalarm" :size="8" color="text" class="mr-2" />
							Jobalarm aktivieren
						</div>
					</template>
				</div>
			</section>
			<section
				class="w-full flex flex-col"
				:class="$isMobile.any ? 'items-center pt-12' : 'items-start  pt-6 pb-4'"
			>
				<div v-if="editMode && $isMobile.any" class="block text-center w-full">
					<HokButton
						class="ml-4 -mt-6 p-1 absolute text-sm"
						style="padding: 0.25rem"
						size="small"
						color="business"
						@click="$emit('edit', 'logo')"
					>
						<HokIcon name="icon:edit" :size="4" color="white"
					/></HokButton>
				</div>
				<h1
					v-if="company && company.name"
					class="text-center text-2xl break-words leading-normal mb-0 text-color-text font-bold"
					:class="$isMobile.any ? 'mb-0 mt-2' : 'mb-1 mt-0'"
				>
					{{ company.name }}
				</h1>
				<Location :company="company" />
			</section>
			<section
				v-if="!$isMobile.any && (jobFieldCount || editMode)"
				class="pt-5 pb-4 border-b border-color-grey-light"
			>
				<h3 id="fields" class="text-2xs text-color-text font-light mb-2">TÄTIGKEITSBEREICHE</h3>
				<div class="flex flex-wrap">
					<div
						v-for="(field, index) in visibleFields"
						:key="field._id"
						class="text-sm font-light tracking-wide bg-color-grey-lightest hover:bg-color-grey-light px-3 py-1 rounded-full mb-2 mr-2"
						data-cy="branchElement"
					>
						<span
							v-if="
								company &&
								company.fields &&
								company.fields.length > 5 &&
								index === 4 &&
								!showAllFields
							"
							class="cursor-pointer"
							@click="showAllFields = true"
						>
							+{{ company && company.fields && company.fields.length - 4 }} Kategorien
						</span>
						<span v-else class="cursor-pointer" @click="$emit('field-clicked', field.name)">{{
							field.name
						}}</span>
					</div>
				</div>
				<div v-if="editMode">
					<HokButton
						class="my-2 text-sm"
						size="small"
						data-cy="editBranches"
						color="business"
						@click="$emit('edit', 'fields')"
					>
						<HokIcon name="icon:edit" :size="4" class="mr-1" color="white" />
						Bereiche bearbeiten
					</HokButton>
				</div>
			</section>
			<section :class="$isMobile.any ? 'pt-8' : 'pt-12 px-3'">
				<div
					v-if="company.description || editMode"
					id="about-us"
					ref="about-us"
					class="mb-8"
					:class="$isMobile.phone ? 'scroll-mt-5' : 'scroll-mt-20'"
				>
					<h3
						class="text-2xl font-light text-color-text"
						:class="$isMobile.phone ? 'mb-3' : 'mb-5'"
					>
						Über Uns
					</h3>
					<BeautifyText
						v-if="company.description"
						class="font-light"
						:text="
							truncateFct(company.description, showFullText ? company.description.length : 500)
						"
						data-cy="description"
					/>
					<div
						v-show="!showFullText && company.description && company.description.length > 500"
						class="cursor-pointer text-color-main mt-2"
						@click="showFullText = true"
					>
						Mehr anzeigen
					</div>
					<p
						v-if="company.description && company.description.length === 0 && editMode"
						class="text-color-grey"
					>
						Sie haben noch keine Unternehmensbeschreibung eingetragen.
					</p>
				</div>
				<HokButton
					v-if="editMode"
					class="mb-5 text-sm"
					size="small"
					data-cy="editDescription"
					color="business"
					@click="$emit('edit', 'description')"
				>
					<HokIcon name="icon:edit" :size="4" class="mr-1" color="white" />
					Beschreibung bearbeiten
				</HokButton>
				<div v-if="mediaCount" class="mb-6">
					<div v-if="company.description" class="mb-8 flex justify-center">
						<hr class="w-56" />
					</div>
					<div id="masonry" class="relative w-full flex overflow-hidden mb-12 h-40 rounded-lg">
						<NuxtImg
							v-for="(item, index) in media"
							:key="index"
							:loading="$isMobile.phone ? 'lazy' : undefined"
							:src="item.thumbnail"
							:alt="item.title || 'Gallerie Vorschau-Bild'"
							class="flex items-center justify-center cursor-pointer rounded-lg h-40 mr-2"
							data-cy="mediaItem"
							@click="$emit('open-media', item)"
						>
							<HokIcon
								v-if="item.type === 'youtube'"
								:size="16"
								color="white"
								name="icon:play"
								class="bg-color-transparent-black rounded-full"
							/>
						</NuxtImg>
						<div
							v-if="($isMobile.phone && mediaCount >= 2) || (!$isMobile.phone && mediaCount >= 4)"
							class="absolute h-40 w-48 bg-color-white right-0 inset-y-0 flex items-center justify-end fading-white-gradient cursor-pointer"
							@click="$emit('open-media', media && media[0])"
						>
							<HokIcon name="icon:add" :size="7" color="blue" />
							<div class="text-color-blue leading-tight font-light ml-2 mr-5 text-sm">
								Mehr <br />
								anzeigen
							</div>
						</div>
					</div>
					<div v-if="editMode" class="text-center">
						<HokButton
							class="my-2 text-sm"
							size="small"
							data-cy="editMedia"
							color="business"
							@click="$emit('edit', 'media')"
						>
							<HokIcon name="icon:edit" :size="4" class="mr-1" color="white" />
							Fotos bearbeiten
						</HokButton>
					</div>
				</div>
				<div v-if="!mediaCount && editMode" class="mb-6">
					<div v-if="company.description" class="mb-8 flex justify-center">
						<hr class="w-56" />
					</div>
					<p class="text-color-grey text-center">Sie haben noch keine Fotos hinzugefügt.</p>
					<div class="text-center">
						<HokButton
							class="my-2 text-sm"
							size="small"
							data-cy="editMedia"
							color="business"
							@click="$emit('edit', 'media')"
						>
							<HokIcon name="icon:edit" :size="4" class="mr-1" color="white" />
							Fotos bearbeiten
						</HokButton>
					</div>
				</div>
				<div
					v-if="hasTrustedUsers && !editMode"
					id="contact-person"
					ref="contact-person"
					class="mb-8"
					:class="$isMobile.phone ? 'scroll-mt-5' : 'scroll-mt-20'"
				>
					<h3
						class="text-2xl font-light text-color-text"
						:class="$isMobile.phone ? 'mb-3' : 'mb-5'"
					>
						{{ contactPersonHeadline }}
					</h3>
					<LazyHokCarousel
						v-if="availableTrustedUsers > 1"
						auto-play
						:items-to-show="1"
						:play-speed="3000"
						:transition="500"
						:items="
							visibleTrustedUsers &&
							visibleTrustedUsers.map(user => ({
								component: companyUserComponent,
								props: {
									user: user
								}
							}))
						"
					/>
					<div
						v-else
						class="flex items-center justify-between"
						:class="{ 'flex-col': $isMobile.any }"
					>
						<div
							v-if="visibleTrustedUsers"
							class="flex items-center self-start"
							:class="$isMobile.phone ? 'mb-4' : 'mb-0'"
						>
							<NuxtImg
								v-if="visibleTrustedUsers[0].images"
								class="w-16 h-16 rounded-full shadow-md mr-4"
								:src="visibleTrustedUsers[0].images.small"
								:alt="visibleTrustedUsers[0].displayName || 'Dein Ansprechpartner'"
							/>
							<div
								v-else
								class="bg-color-grey w-32 h-32 rounded-full flex justify-center items-center text-5xl text-color-white font-semibold z-10"
							/>
							<div class="font-light">
								<div class="text-xl">
									{{ visibleTrustedUsers[0].displayName || 'Dein Ansprechpartner' }}
								</div>
								<div
									v-if="
										visibleTrustedUsers[0].recruiter && visibleTrustedUsers[0].recruiter.position
									"
									class="text-color-text-lighter"
								>
									{{ visibleTrustedUsers[0].recruiter.position }}
								</div>
							</div>
						</div>
						<blockquote
							v-if="
								visibleTrustedUsers &&
								visibleTrustedUsers[0].recruiter &&
								visibleTrustedUsers[0].recruiter.slogan
							"
							class="max-w-lg font-light italic text-color-text-lighter"
							:class="$isMobile.phone ? 'px-5' : 'pr-0'"
						>
							"{{
								truncateFct(
									visibleTrustedUsers[0].recruiter.slogan,
									showFullSlogan ? visibleTrustedUsers[0].recruiter.slogan.length : 300
								)
							}}"
							<HokButton
								v-if="visibleTrustedUsers[0].recruiter.slogan.length > 300"
								is-text
								size="small"
								color="main"
								font-weight="light"
								@click="showFullSlogan = !showFullSlogan"
							>
								{{ showFullSlogan ? 'Weniger anzeigen' : 'Mehr anzeigen' }}</HokButton
							>
						</blockquote>
					</div>
				</div>
				<div v-if="editMode">
					<h3
						class="text-2xl font-light text-color-text"
						:class="$isMobile.phone ? ' mb-3' : 'mb-5'"
					>
						AnsprechpartnerIn
					</h3>
					<p class="rounded-lg bg-color-grey-lightest p-5">
						Die Ansprechpartner des Unternehmens werden automatisch in Ihrem Unternehmensprofil
						angezeigt.
						<br />
						<template v-if="company && company.webUrl"
							>Für eine aktuelle Vorschau wechseln Sie in die
							<HokLink
								class="hover:text-color-main-hover inline-block"
								:to="company.webUrl"
								target="_blank"
								is-text
								color="business"
								>Webansicht</HokLink
							>.<br />
						</template>
						<HokButton
							class="my-2 text-sm"
							size="small"
							color="business"
							@click="$modal.show('info-contact-persons')"
						>
							<HokIcon name="icon:edit" :size="4" class="mr-1" color="white" />
							Ansprechpartner bearbeiten</HokButton
						>
					</p>
				</div>
				<div v-if="$isMobile.any && (jobFieldCount || editMode)" class="mb-8">
					<div class="mb-8 flex justify-center">
						<hr class="w-56" />
					</div>
					<Fields
						:company="company"
						:show-all-fields="false"
						v-bind="$attrs"
						@showAllFields="showAllFields = $event"
					/>
					<div v-if="editMode">
						<HokButton
							class="my-2 text-sm"
							size="small"
							color="business"
							@click="$emit('edit', 'fields')"
						>
							<HokIcon name="icon:edit" :size="4" class="mr-1" color="white" />
							Bereiche bearbeiten
						</HokButton>
					</div>
				</div>

				<div v-if="editMode">
					<h3
						class="text-2xl font-light text-color-text"
						:class="$isMobile.phone ? ' mb-3' : 'mb-5'"
					>
						Aktuelle Jobs
					</h3>
					<p class="rounded-lg bg-color-grey-lightest p-5">
						Ihre aktuellen Jobs werden automatisch in Ihrem Unternehmensprofil angezeigt.
						<br />
						<template v-if="company && company.webUrl"
							>Für eine aktuelle Vorschau wechseln Sie in die
							<HokLink
								class="hover:text-color-main-hover inline-block"
								:to="company.webUrl"
								target="_blank"
								is-text
								color="business"
								>Webansicht</HokLink
							>.<br />
						</template>
						<HokButton
							to="/pwa/company/pricelist"
							class="my-2 text-sm"
							size="small"
							color="business"
						>
							Neuen Job veröffentlichen</HokButton
						>
					</p>
				</div>
				<div
					v-else
					id="jobs"
					ref="jobs"
					class="mb-8"
					:class="$isMobile.phone ? 'scroll-mt-5' : 'scroll-mt-20'"
				>
					<div class="mb-8 flex justify-center">
						<hr class="w-56" />
					</div>
					<slot name="jobs">
						<h2 class="text-left text-2xl font-light text-color-text mb-0">
							Wir haben aktuell keine Jobs für dich.
						</h2>
					</slot>
				</div>

				<div v-if="!editMode" id="initiative" class="mb-8">
					<div class="mb-8 flex justify-center">
						<hr class="w-56" />
					</div>
					<h3 v-if="!isCompanySwipeActionDiscover" class="text-2xl font-light text-color-text mb-8">
						Nicht der passende Job dabei?
					</h3>
					<p class="text-sm" :class="{ 'text-center': $isMobile.any }">
						Auch wenn gerade kein passender Job für dich dabei ist, bleibe auf dem Laufenden und
						aktiviere einen Jobalarm oder bewirb dich initiativ!
					</p>
					<div class="flex flex-col" :class="{ 'items-start': !$isMobile.any }">
						<HokButton
							v-if="company.allowUnsolicitedApplications && !isCompanySwipeActionDiscover"
							class="mb-3"
							:is-text="!$isMobile.any"
							color="main"
							fullwidth="mobile"
							font-weight="normal"
							size="medium"
							link-type="external"
							invisible
							:to="`/apply-company/${company._id}`"
						>
							<HokIcon v-if="!$isMobile.any" name="icon:initiative" class="mr-2" />
							Initativ bewerben
						</HokButton>
						<HokButton
							class="mb-3"
							color="main"
							fullwidth="mobile"
							:outline="
								$isMobile.any &&
								company.allowUnsolicitedApplications &&
								!isCompanySwipeActionDiscover
							"
							:is-text="!$isMobile.any"
							font-weight="normal"
							size="medium"
							@click="$emit('activate-jobalarm')"
						>
							<HokIcon v-if="!$isMobile.any" name="icon:jobalarm" class="mr-2" />
							Jobalarm aktivieren
						</HokButton>
					</div>
				</div>
				<div v-if="locationCount || editMode" class="mb-8">
					<div class="mb-8 flex justify-center">
						<hr class="w-56" />
					</div>
					<h3
						id="locations"
						ref="locations"
						class="text-2xl font-light text-color-text"
						:class="$isMobile.phone ? ' mb-5 scroll-mt-5' : 'mb-6 scroll-mt-20'"
					>
						Standorte
					</h3>
					<div class="flex items-start" :class="{ 'flex-col': $isMobile.phone }">
						<div :class="$isMobile.phone ? 'w-full' : 'w-1/2'">
							<div
								v-if="(company.staticMapImageUrl && locationCount) || editMode"
								class="w-full relative"
								:class="$isMobile.any ? 'h-[250px]' : 'h-[300px]'"
							>
								<a
									v-if="
										$isMobile.any &&
										primaryLocation &&
										(primaryLocation.name || primaryLocation.city)
									"
									:href="`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
										primaryLocation.name || primaryLocation.city || '#'
									)}`"
									target="_blank"
									rel="noopener noreferrer"
									aria-label="Google Maps in neuem Fenster öffnen"
								>
									<NuxtImg
										loading="lazy"
										:src="company.staticMapImageUrl"
										placeholder="/shared-components-nuxt3/images/map-placeholder-big.jpg"
										alt="Firmenstandorte"
										class="object-cover h-full w-full"
									/>
								</a>
								<NuxtImg
									v-else-if="mapState === 'image' || (locationCount === 0 && editMode)"
									loading="lazy"
									class="object-cover h-full w-full"
									placeholder="/shared-components-nuxt3/images/map-placeholder-big.jpg"
									:src="locationCount === 0 ? mapFallbackImage : company.staticMapImageUrl"
									alt="Firmenstandorte"
									@click="setMapState('map')"
								/>
								<LazyHokMarkerMap
									v-else
									map-key="companypage"
									:country="topLevelDomain"
									:locations="company.location"
									:height="$isMobile.any ? 250 : 300"
								/>
							</div>
						</div>
						<div
							:class="{
								'w-1/2 pl-10': company.staticMapImageUrl && !$isMobile.phone,
								'mt-6': company.staticMapImageUrl && $isMobile.phone
							}"
							class="w-full"
						>
							<div v-if="primaryLocation" class="mb-6 font-light text-color-text-lighter">
								<h4 class="mb-3 font-normal text-xl">{{ company.name }}</h4>
								<div class="flex">
									<HokIcon :size="7" name="icon:pin-2" />
									<div class="flex flex-col leading-tight" data-cy="primaryLocation">
										<span v-if="primaryLocation.street || primaryLocation.streetNumber"
											>{{ primaryLocation.street }} {{ primaryLocation.streetNumber }}</span
										>
										<span v-if="primaryLocation.code || primaryLocation.city"
											>{{ primaryLocation.code }} {{ primaryLocation.city }}</span
										>
										<span v-if="primaryLocation.country">{{ primaryLocation.country }}</span>
									</div>
								</div>
							</div>
							<p v-else class="text-color-grey">Noch keine Adresse eingefügt</p>
							<div v-if="secondaryLocations && secondaryLocations.length > 0">
								<h4 class="mb-4 font-normal">Weitere Standorte:</h4>
								<div class="flex flex-wrap">
									<div
										v-for="location in visibleSecondaryLocations"
										:key="location.name"
										class="flex w-1/2 mb-5 font-light text-color-text-lighter"
									>
										<HokIcon :size="7" name="icon:pin-2" />
										<div class="flex flex-col leading-tight">
											<span v-if="location.street || location.streetNumber"
												>{{ location.street }} {{ location.streetNumber }}</span
											>
											<span v-if="location.code || location.city"
												>{{ location.code }} {{ location.city }}</span
											>
											<span v-if="location.country">{{ location.country }}</span>
										</div>
									</div>
								</div>
								<LazyPagination
									v-if="locationPageCount > 1"
									:current-page="currentLocationPage"
									:page-count="locationPageCount"
									arrow-pagination
									class="mx-auto"
									:class="{ 'max-w-[250px]': !$isMobile.any }"
									@page-nav="updateCurrentLocationPage"
								/>
							</div>
						</div>
					</div>
					<div v-if="editMode" class="text-right">
						<HokButton
							class="my-2 text-sm"
							size="small"
							data-cy="editLocations"
							color="business"
							@click="$emit('edit', 'address')"
						>
							<HokIcon name="icon:edit" :size="4" class="mr-1" color="white" />
							Adressen bearbeiten / hinzufügen</HokButton
						>
					</div>
				</div>

				<!-- offline Jobs only for SEO -->
				<slot name="offline-jobs" />
			</section>
			<section :class="{ 'px-3': !$isMobile.any }">
				<hr />
				<div class="flex flex-col mt-8 space-y-3">
					<HokLink
						v-if="company.url"
						:to="company.url"
						class="hover:underline hover:text-color-main-hover"
						data-cy="companyUrl"
						>Zur Website des Unternehmens</HokLink
					>
					<HokLink v-if="!editMode" :to="companyClaimMail" class="text-color-text hover:underline"
						>Ihr Unternehmen? Jetzt Profil einrichten</HokLink
					>
				</div>
				<HokButton
					v-if="editMode"
					class="text-sm my-3"
					size="small"
					data-cy="editUrl"
					color="business"
					@click="$emit('edit', 'url')"
				>
					<HokIcon name="icon:edit" :size="4" class="mr-1" color="white" />
					Website
					{{ company.url ? 'bearbeiten' : 'hinzufügen' }}</HokButton
				>
			</section>
		</div>
		<slot name="edit" />
		<HokModal name="info-contact-persons" width="320px">
			<h3>AnsprechpartnerIn</h3>
			<p>Ansprechpartner können Ihre Darstellung im persönlichen Profil bearbeiten.</p>
			<HokButton fullwidth="always" to="/pwa/company/settings" class="mb-3" color="business"
				>Zum Profil wechseln</HokButton
			>
			<HokButton
				fullwidth="always"
				outline
				color="business"
				@click="$modal.hide('info-contact-persons')"
				>Verstanden</HokButton
			>
		</HokModal>
	</div>
</template>

<script lang="ts">
import type { IAPICompany, IAPICompanyMedia, IAPIJobField, IAPILocation } from '@hokify/common';
import type { PropType } from 'vue';
import { defineComponent, defineAsyncComponent } from 'vue';
import BeautifyText from './BeautifyText.vue';
import { truncateFct } from '../helpers/truncate';
import Location from './Company/Location.vue';
import Fields from './Company/Fields.vue';
import HokBgImg from './HokBgImg.vue';
import LazyHokMarkerMap from './HokMarkerMap.vue';
import LazyPagination from './Pagination.vue';
import LazyHokCarousel from './HokCarousel.vue';

function isHTMLElement(element: any): element is HTMLElement {
	return element && element instanceof HTMLElement;
}

interface IAdditionalData {
	jobCount: number;
	useBodyContainer: boolean;
}

type MapState = 'image' | 'map';

export default defineComponent({
	name: 'CompanyPage',
	components: {
		Fields,
		BeautifyText,
		LazyHokCarousel,
		LazyPagination,
		LazyHokMarkerMap,
		Location,
		HokBgImg
	},
	emits: ['switch-to-applications', 'activate-jobalarm', 'edit', 'field-clicked', 'open-media'],
	created() {
		this.companyUserComponent = defineAsyncComponent(() => import('./CompanyUserCarousel.vue'));
	},
	beforeUnmount() {
		this.observer?.disconnect();
	},
	methods: {
		truncateFct,
		setMapState(state: MapState) {
			this.mapState = state;
		},
		toAnchor(e, anchor: string) {
			e.preventDefault();

			if (this.editMode && anchor === 'initiative') {
				this.$emit('switch-to-applications');
			}
			const element = this.$refs[anchor];
			if (isHTMLElement(element)) {
				element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
		},
		updateCurrentLocationPage(direction: 'next' | 'prev') {
			if (direction === 'next') {
				this.currentLocationPage += 1;
			} else {
				this.currentLocationPage -= 1;
			}
		},
		registerIntersectionObserver() {
			const navigationSections = document.querySelectorAll(
				`#about-us,#contact-person, #jobs, #locations, #start `
			);
			const navMenu = document.querySelectorAll('#nav-menu li');

			const options = {
				rootMargin: '-10% 0px -90% 0px',
				threshhold: 0.5
			};

			this.observer = new IntersectionObserver(entries => {
				entries.forEach(e => {
					if (e.isIntersecting) {
						navMenu.forEach(item => {
							const navItemName = item.id.replace('nav-', '');
							item.classList.remove('font-bold');
							if (e.target.id === navItemName) {
								item.classList.add('font-bold');
							}
						});
					}
				});
			}, options);

			navigationSections.forEach(section => {
				this.observer?.observe(section);
			});
		}
	},
	data() {
		const observer = undefined as undefined | IntersectionObserver;
		const mapState = 'image' as MapState;
		const companyUserComponent: any = undefined;

		return {
			mapState,
			showFullText: false,
			showAllFields: false,
			currentLocationPage: 1,
			maxSecondaryLocations: this.$isMobile.phone ? 4 : 2,
			observer,
			showFullSlogan: false,
			companyUserComponent
		};
	},
	computed: {
		topLevelDomain(): string {
			return this.$nuxt?.$userRootStore?.topLevelDomain || 'at';
		},
		visibleFields(): IAPIJobField[] | undefined {
			return this.showAllFields ? this.company?.fields : this.company?.fields?.slice(0, 5);
		},
		coverImages(): string {
			if (this.company?.coverImage?.medium) {
				return this.company.coverImage.medium;
			}
			return '/shared-components-nuxt3/images/company-placeholder-header.png';
		},
		mediaCount(): number {
			return this.media?.length || 0;
		},
		media(): IAPICompanyMedia[] | undefined {
			return this.company?.about?.media;
		},
		locationCount(): number | undefined {
			return this.company?.location?.length;
		},
		jobFieldCount(): number | undefined {
			return this.company?.fields?.length;
		},
		jobCount(): number | undefined {
			return this.additionalData?.jobCount;
		},
		primaryLocation(): IAPILocation | undefined {
			return this.company?.location?.[0];
		},
		secondaryLocations(): IAPILocation[] | undefined {
			return this.company?.location?.slice(1); // returns all items from and including index 1
		},
		isCompanySwipeActionDiscover(): boolean {
			return this.company?.swipeAction === 'discover';
		},
		visibleSecondaryLocations(): IAPILocation[] | undefined {
			if (
				this.secondaryLocations?.length &&
				this.secondaryLocations.length <= this.maxSecondaryLocations
			) {
				return this.secondaryLocations;
			}
			return this.secondaryLocations?.slice(
				0 + this.maxSecondaryLocations * (this.currentLocationPage - 1),
				this.maxSecondaryLocations + this.maxSecondaryLocations * (this.currentLocationPage - 1)
				// e.g. (0, 2), (2, 4), etc
			);
		},
		contactPersonHeadline(): string {
			if (this.visibleTrustedUsers && this.visibleTrustedUsers.length > 1) {
				return 'Deine Ansprechpartner';
			}
			return this.visibleTrustedUsers?.[0]?.general?.gender === 'female'
				? 'Deine Ansprechpartnerin'
				: 'Dein Ansprechpartner';
		},
		hasTrustedUsers(): boolean {
			return !!this.company?.organization?.owners?.length;
		},
		visibleTrustedUsers(): any[] | undefined {
			if (this.availableTrustedUsers < 1) return undefined;
			return this.company?.organization?.owners;
		},
		availableTrustedUsers(): number {
			return this.company?.organization?.owners?.length || 0;
		},
		getLocationText(): string {
			if (this.primaryLocation?.name && this.primaryLocation?.name.length < 40) {
				return this.primaryLocation?.name;
			}
			if (this.primaryLocation?.district && this.primaryLocation?.country) {
				return `${this.primaryLocation?.district}, ${this.primaryLocation?.country}`;
			}
			if (this.primaryLocation?.city && this.primaryLocation?.country) {
				return `${this.primaryLocation?.city}, ${this.primaryLocation?.country}`;
			}
			return this.primaryLocation?.name || '';
		},
		companyClaimMail(): string {
			const title = `Unternehmens-Page bearbeiten - https://hokify.${this.topLevelDomain}/c/${this.company.alias}`;
			return encodeURI(`mailto:info@hokify.at?subject=${title}`);
		},
		enoughDataForSplitTiles(): boolean {
			// count if there is enough data to support 3 tiles
			return (
				[
					this.locationCount,
					this.jobFieldCount,
					this.jobCount,
					this.company?.allowUnsolicitedApplications
				].filter(truthy => truthy).length >= 2
			);
		},
		locationPageCount(): number {
			if (this.secondaryLocations?.length) {
				return Math.ceil(this.secondaryLocations.length / this.maxSecondaryLocations);
			}
			return 0;
		},
		mapFallbackImage(): string {
			switch (this.topLevelDomain) {
				case 'de':
					return '/shared-components-nuxt3/images/gmap_germany.png';
				case 'ch':
					return '/shared-components-nuxt3/images/gmap_switzerland.png';
				case 'at':
				default:
					return '/shared-components-nuxt3/images/gmap_austria.png';
			}
		}
	},
	mounted() {
		if (!this.$isMobile.any) {
			this.registerIntersectionObserver();
		}
	},
	props: {
		company: {
			type: Object as PropType<IAPICompany>,
			required: true,
			default: () => ({})
		},
		additionalData: {
			type: Object as PropType<IAdditionalData>,
			required: false
		},
		editMode: {
			type: Boolean,
			default: false
		},
		displayCTAButtons: {
			type: Boolean,
			default: true
		}
	}
});
</script>

<style lang="scss" scoped>
.bg-color-transparent-black {
	background-color: rgba(0, 0, 0, 0.5);
}

.fading-white-gradient {
	mask-image: linear-gradient(
		-90deg,
		rgba(255, 255, 255, 0.966) 40%,
		rgba(255, 255, 255, 0.7) 70%,
		rgba(255, 255, 255, 0) 95%
	);
}

// overwrite IE11 "fix" for this special case, to prevent it breaks
// wont be sticky in IE11 though
.sticky {
	position: relative;
	position: sticky;
}
</style>
