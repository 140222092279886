<template>
	<div v-if="!arrowPagination" class="px-5 sm:px-0 w-full flex flex-col">
		<HokButton
			id="nextpage"
			:disabled="currentPage === pageCount"
			:color="currentPage === pageCount ? 'grey' : 'main'"
			size="big"
			@click="$emit('page-nav', 'next')"
		>
			Nächste Seite
		</HokButton>
		<HokButton
			v-show="currentPage > 1"
			id="previouspage"
			is-text
			color="main"
			class="mt-4"
			@click="$emit('page-nav', 'prev')"
		>
			Vorherige Seite
		</HokButton>
	</div>
	<div v-else class="px-5 w-full flex justify-between items-center">
		<HokButton
			id="previousPage"
			:disabled="currentPage <= 1"
			color="main"
			class="w-10 h-10 pl-0 pr-1"
			@click="$emit('page-nav', 'prev')"
		>
			<HokIcon color="white" name="icon:arrow-left" :size="4" />
		</HokButton>
		<p class="text-sm mb-0">
			Seite <strong>{{ currentPage }}</strong> von {{ pageCount }}
		</p>
		<HokButton
			id="nextPage"
			:disabled="currentPage === pageCount"
			color="main"
			class="w-10 h-10 pl-1 pr-0"
			@click="$emit('page-nav', 'next')"
		>
			<HokIcon color="white" name="icon:arrow-right" :size="4" />
		</HokButton>
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ButtonRound from './ButtonRound.vue';

export default defineComponent({
	name: 'Pagination',
	components: {
		ButtonRound
	},
	props: {
		currentPage: { type: Number, required: true },
		pageCount: { type: Number, required: true },
		arrowPagination: { type: Boolean, default: false }
	}
});
</script>

<style scoped lang="scss"></style>
