<template>
	<Spinner v-if="loading" />
	<div v-else>
		<CompanyPage
			:company="company"
			:additional-data="{ jobCount, useBodyContainer: false }"
			class="pb-20"
			@open-media="openMedia"
			@activate-jobalarm="$emit('activate-jobalarm')"
		>
			<template v-if="jobCount" #jobs>
				<h3 class="text-2xl font-light">
					Wir haben aktuell <template v-if="jobCount > 1">{{ jobCount }} Jobs&nbsp;</template
					><template v-else-if="jobCount === 1">einen Job&nbsp;</template>für dich.
				</h3>
				<JobList
					v-if="!$isMobile.phone"
					:relations="previewJobsRelations"
					:similar-job-list="true"
					lean-list
					v-bind="$attrs"
				/>
				<template v-else>
					<JobListItem
						v-for="(previewJob, index) in previewJobs"
						:key="index"
						class="rounded-xl border-2 border-color-grey-lightest mb-3"
						:job="previewJob"
						:minify="true"
					/>
				</template>
				<div v-if="company" class="mx-auto text-center mt-8">
					<HokButton color="main" @click="setCompanyFilter(company)"
						>Alle<template v-if="jobCount > 1">&nbsp;{{ jobCount }}</template
						>&nbsp;Jobs anzeigen</HokButton
					>
				</div>
			</template>
		</CompanyPage>
		<Lightbox v-if="media" ref="lightbox" :media="media" />
	</div>
</template>

<script lang="ts">
import CompanyPage from '@hokify/shared-components-nuxt3/lib/components/CompanyPage.vue';
import JobList from '@hokify/shared-components-nuxt3/lib/components/JobList.vue';
import JobListItem from '@hokify/shared-components-nuxt3/lib/components/JobListItem.vue';
import Lightbox from '@hokify/shared-components-nuxt3/lib/components/Lightbox.vue';
import { EventBus } from '@hokify/shared-components-nuxt3/lib/eventbus';
import type {
	IAPICompany,
	IAPIJobPreviewForUser,
	APITypeObjectId,
	APIObjectType,
	IAPICompanyMedia
} from '@hokify/common';
import type { IMediaContent } from '@hokify/shared-components-nuxt3/lib/types/lightBox';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { mapStores } from 'pinia';
import { useCompanyStore } from '@/stores/company';
import { useRelationsStore } from '@/stores/relations';
import { useUserJobFilterStore } from '@/stores/user-job-filter';

function hasOpenLightbox(obj: any): obj is { openLightbox(obj): void } {
	return typeof obj.openLightbox === 'function';
}

export default defineComponent({
	name: 'CompanyPageWrapper',
	components: {
		CompanyPage,
		JobList,
		JobListItem,
		Lightbox
	},
	emits: ['activate-jobalarm'],
	data() {
		const company = undefined as IAPICompany | undefined;
		const previewJobs: IAPIJobPreviewForUser[] = [];

		return {
			previewJobs,
			jobCount: 0,
			company,
			loading: true,
			fullyLoadedCompany: false,
			validMediaType: ['youtube', 'link', 'image'],
			EventBus
		};
	},
	computed: {
		media(): IAPICompanyMedia[] | undefined {
			return this.company?.about?.media;
		},
		lightbox() {
			return this.$refs.lightbox as any;
		},
		previewJobsRelations(): { type: 'job'; obj: IAPIJobPreviewForUser }[] {
			const extendedArray: { type: 'job'; obj: IAPIJobPreviewForUser }[] = [];
			this.previewJobs.forEach(element => {
				extendedArray.push({ type: 'job', obj: element });
			});
			return extendedArray;
		},
		...mapStores(useCompanyStore, useRelationsStore, useUserJobFilterStore)
	},
	mounted() {
		this.EventBus.$on('load-company-details', this.fullyLoadCompany);
		if (this.loadInstantly) {
			this.fullyLoadCompany();
		}
	},
	methods: {
		beforeUnmount() {
			this.EventBus.$off('load-company-details', this.fullyLoadCompany);
		},
		openMedia(obj: IMediaContent) {
			if (this.validMediaType.includes(obj.type)) {
				if (hasOpenLightbox(this.lightbox)) {
					this.lightbox?.openLightbox(obj);
				}
			} else {
				this.$sentry.captureMessage(`company media: type ${obj.type} is not supported!`);
			}
		},
		async fullyLoadCompany() {
			if (this.fullyLoadedCompany) return;

			this.loading = true;
			if (this.companyId) {
				try {
					const res = await this.companyStore.getCompany(this.companyId);
					const { previewJobs, jobCount, company } = res;
					this.previewJobs = previewJobs;
					this.jobCount = jobCount;
					this.company = company;
					this.fullyLoadedCompany = true;
				} catch (e) {
					console.error(e);
					this.$snack.danger({
						title: 'Fehlgeschlagen!',
						text: `Beim Laden der Firmendetails ist etwas schief gelaufen. Bitte probiere es etwas später erneut.`
					});
				} finally {
					this.loading = false;
				}
			}
		},
		async setCompanyFilter(company: IAPICompany) {
			try {
				await this.userJobFilterStore
					.setFilter({ filters: [`company-${company.alias}`] })
					.then(() => {
						this.$trackUserEvent?.('set_jobsearch_filter', {
							filterType: 'company',
							filterValue: company?.alias
						});
					});
				// reset searchResults after setting filter and load new cards
				await this.relationsStore.loadMoreCards(true).then(() => {
					this.$trackUserEvent?.('list_view', {
						relations: this.relationsStore.searchresults.list
							.filter(obj => obj.type === 'matchrelation')
							.map(
								obj =>
									obj.type === 'matchrelation' && {
										relation: obj.relation.type,
										relationId: obj.relation.obj._id,
										audienceId:
											(obj.relation.type === 'company' && obj.relation.audienceId) || undefined
									}
							)
					});
				});
				this.$router.push({ path: '/pwa' });
				this.$snack.success({
					text: `Du siehst jetzt alle Jobs von ${company.name}.`
				});
			} catch (e) {
				console.error(e);
				this.$snack.danger({
					title: 'Fehlgeschlagen!',
					text: `Beim Setzens des Filters auf ${company.name} ist etwas schief gelaufen.`
				});
			}
		}
	},
	props: {
		companyId: {
			type: String as PropType<APITypeObjectId<APIObjectType.Company>>,
			required: true
		},
		loadInstantly: { type: Boolean, default: true }
	}
});
</script>
